<template>
  <v-container fluid class="grey lighten-4">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          Kierowcy
          <v-spacer></v-spacer>
          <v-text-field v-model="search" label="Szukaj" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="tableHeaders"
          :items="drivers"
          :sort-by="['driver_id']"
          :sort-desc="[false, true]"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              @click="
                editedDriver = item;
                editData = !editData;
              "
            >
              <p class="ma-0">Edytuj</p>
            </v-btn>
          </template>

          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Kierowcy</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark class="mb-2" v-on="on">Nowy kierowca</v-btn>
                </template>
                <v-card>
                  <v-toolbar flat color="black" :dark="true">
                    <v-toolbar-title>Dodaj kierowcę</v-toolbar-title>
                    <v-spacer />
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-alert class="col-12" v-if="message" type="error">
                          {{ message }}
                        </v-alert>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Nazwa"
                            :rules="rulesName"
                            maxlength="30"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.phone"
                            label="Nr telefonu"
                            :rules="rulesPhone"
                            maxlength="50"
                          ></v-text-field>
                        </v-col>

                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      color="amber accent-3"
                      class="grey--text text--darken-4"
                      outlined
                      :x-large="true"
                      v-tilt
                      @click="close"
                    >
                      Anuluj
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="amber accent-3"
                      class="grey--text text--darken-4"
                      :x-large="true"
                      v-tilt
                      @click="save"
                    >
                      Dodaj
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="editData" :dark="false" :light="true" max-width="600px">
                <v-toolbar flat color="black" :dark="true">
                  <v-toolbar-title>Edytuj</v-toolbar-title>
                  <v-spacer />
                  <v-btn @click="editData = false" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <EditDriver :driver="editedDriver"></EditDriver>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <a :href="'mailto:' + item.email">{{ item.email }}</a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { mdiPackageVariant, mdiPin, mdiContacts } from '@mdi/js';

  import axios from 'axios';

  import EditDriver from '../../components/EditDriver.vue';

  export default {
    components: {
      EditDriver,
    },
    props: {
      source: String,
    },
    data: () => ({
      icons: {
        mdiPackageVariant,
        mdiPin,
        mdiContacts,
      },
      rulesName: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z0-9_-zżźćńółęąśŻŹĆĄŚĘŁÓŃ ]{1,50}$/.test(v) ||
          'Imię ma niepoprawny format (mininimum 3, a maksymalnie 50 znaków)',
      ],
      rulesPhone: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[0-9 ]{3,25}$/.test(v) ||
          'Nazwisko ma niepoprawny format (mininimum 3, a maksymalnie 25 znaków)'
      ],
      tableHeaders: [
        {
          text: 'LP',
          align: 'left',
          value: 'driver_id',
        },
        {
          text: 'Nazwa',
          value: 'name',
        },
        {
          text: 'Numer telefonu',
          value: 'phone',
        },
        {
          text: 'Edytuj',
          value: 'edit',
        },
      ],
      drivers: [],
      search: null,
      dialog: false,
      editedItem: {
        name: null,
        phone: null
      },
      defaultItem: {
        name: null,
        phone: null
      },
      message: null,
      editData: false,
      editedDriver: null,
    }),
    methods: {
      close: function () {
        this.dialog = false;
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        }, 300);
      },
      save: function () {
        axios({
          url: this.$store.state.url + '/api/drivers/add',
          headers: {'Authorization': 'Bearer ' + this.$store.state.token},
          data: this.editedItem,
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status !== 200) {
              this.message = resp.data.message;
            } else {
              window.location.reload();
            }
          })
          .catch();
      },
    },
    created() {
      axios({
        url: this.$store.state.url + '/api/drivers/get',
        headers: {'Authorization': 'Bearer ' + this.$store.state.token},
        method: 'GET',
      }).then(resp => {
        this.drivers = resp.data.drivers;
      });
    },
  };
</script>
<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
