<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row>
          <v-alert class="col-12" v-if="message" type="error">
            {{ message }}
          </v-alert>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-text-field
              v-model="editedItem.name"
              label="Nazwa"
              :rules="rulesName"
              maxlength="30"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="editedItem.phone"
              label="Nr telefonu"
              :rules="rulesPhone"
              maxlength="50"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="amber accent-3" class="grey--text text--darken-4" x-large v-tilt @click="save">
        Zatwierdź
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'EditDriver',
    props: {
      driver: Object,
    },
    data: () => ({
      dialog: true,
      editedItem: {
        driver_id: null,
        name: null,
        phone: null
      },
      message: null,

      rulesName: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z0-9_-zżźćńółęąśŻŹĆĄŚĘŁÓŃ ]{1,50}$/.test(v) ||
          'Imię ma niepoprawny format (mininimum 3, a maksymalnie 50 znaków)',
      ],
      rulesPhone: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[0-9 ]{3,25}$/.test(v) ||
          'Nazwisko ma niepoprawny format (mininimum 3, a maksymalnie 25 znaków)'
      ],
    }),
    watch: {
      driver: function () {
        this.getData();
      },
    },
    methods: {
      save: async function () {
        this.message = null;

        const resp = await axios({
          url: this.$store.state.url + '/api/drivers/update',
          headers: {'Authorization': 'Bearer ' + this.$store.state.token},
          data: this.editedItem,
          method: 'PUT',
        })

        if (resp.data.status !== 200) {
          this.message = resp.data.message;
          document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0;
        } else {
          window.location.reload();
        }
      },
      getData: function () {
        Object.assign(this.editedItem, this.driver);
      },
    },
    created() {
      this.getData();
    },
  };
</script>
